import React, { memo } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const apiKey = 'AIzaSyC18ZzctBoGe2DWHeQ4nOvHBZB1mGUVkuk';

const center = {
  lat: 44.813203941959884,
  lng: 20.382993471403775,
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

const Map = () => (
  <LoadScript googleMapsApiKey={apiKey}>
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
      <Marker position={center} />
    </GoogleMap>
  </LoadScript>
);

export default memo(Map);
