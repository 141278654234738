/* eslint-disable react/jsx-one-expression-per-line */
import React, { FC } from 'react';
import { Container, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Paragraph = styled.p`
  text-indent: 20px;
  margin-bottom: 0;
`;

const ServiceDetailText: FC = () => {
  const { id } = useParams();
  const getServiceDetailText = () => {
    switch (id) {
      case 'kardiologija':
        return (
          <>
            <Paragraph>
              Kardiovaskularne bolesti su vodeći uzrok smrti globalno, a pušenje, nedovoljna fizička aktivnost,
              konzumiranje alkohola, nepravilna ishrana i svakodnevni stres, samo su neki od faktora rizika za njihov
              nastanak. Kardiovaskularne bolesti, odnosno bolesti srca i krvnih sudova su:
            </Paragraph>
            <ul>
              <li>koronarna bolest srca</li>
              <li>cerebrovaskularne bolesti</li>
              <li>periferna arterijska bolest</li>
              <li>reumatske bolesti srca</li>
              <li>kongenitalne bolesti</li>
              <li>tromboza</li>
            </ul>
            <Paragraph>
              Da bi se na vreme otkrile, pravovremeno i adekvatno lečile, neophodan je skrining kardiovaskularnog
              rizika. Gotovo 85% prevremene smrtnosti i više od 50% obolevanja od infarkta srca i moždanog udara može da
              se spreči merama primarne prevencije, ranom dijagnostikom i pravovremenim lečenjem KVB. U našoj ordinaciji
              u okviru skrininga kardiovaskularnog rizika možete uraditi:
            </Paragraph>
            <ul>
              <li>usmerene laboratorijske analize</li>
              <li>pregled specijaliste kardiologa</li>
              <li>EKG (elektrokardiografija srca)</li>
              <li>holter EKG, holter pritiska</li>
              <li>EHO pregled srca</li>
            </ul>
            <Divider sx={{ my: 2 }} />
            <b>
              Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!
              <br />
              Naš stručnjak u oblasti kardiologije je Dr Anka Majstorović.
            </b>
          </>
        );
      case 'pulmologija':
        return (
          <>
            <Paragraph>
              Pulmologija je subspecijalistička grana interne medicine koja se bavi prevencijom, dijagnostikom i
              lečenjem akutnih i hroničnih bolesti respiratornog sistema.
            </Paragraph>
            <Paragraph>
              Respiratorni sistem je najotvoreniji sistem čovekovog organizma koji je u neprekidnoj komunikaciji sa
              spoljnom sredinom. Shodno tome, infekcije ovog sistema predstavljaju najčešće infekcije u humanoj
              populaciji.
            </Paragraph>
            <Paragraph>
              Najčešće bolesti respiratornog sistema su infektivne prirode, izazvane bakterijama i virusima (rinitis,
              sinuzitis, bronhitis, pneumonija). U domen pulmologije spadaju:
            </Paragraph>
            <ul>
              <li>alergijske bolesti</li>
              <li>opstruktivne bolesti (HOBP, astma, emfizem)</li>
              <li>restriktivne bolesti (intersticijalne plućne bolesti, fibroza pluća)</li>
              <li>tumori respiratornog trakta</li>
            </ul>
            <Paragraph>
              Astma je hronična upala disajnih puteva koja uzrokuje suženje disajnih puteva, zbog čega se javljaju
              tegobe u vidu osećaja otežanog disanja, kašlja i zviždanja u grudima. Broj obolelih od astme tokom
              poslednje dve decenije stalno raste, naročito kod dece i tinejdžera, te se ona definiše kao bolest
              savremenog društva i epidemija 21. veka.
            </Paragraph>
            <Paragraph>
              Astma se manifestuje nedostatkom vazduha (dispneja), zviždanjem u grudima (vizing) i kašljem. Ova tri
              simptoma su obično udružena. Gušenje se javlja u vidu napada (paroksizmi dispneje) između kojih su faze
              remisije. Intenzitet napada, njihova učestalost i trajanje su varijabini. Za vreme napada bolesnik diše
              ubrzano, čuje se vizing, preznojen je i uplašen. Zauzima sedeći položaj i oslanja se na ispružene ruke, da
              bi fiksirao mišiće ramenog pojasa, što omogućava upotrebu pomoćne disajne muskulature.
            </Paragraph>
            <Paragraph>
              Kraj napada je često obeležen kašljem s iskašljavanjem guste sluzi. Astma se obično pogoršava noću, a
              napadi se javljaju između 2 i 4 sata ujutro. Najteži klinički oblik astme jeste akutni teški napad (status
              asthmaticus) u kome je bolesnik životno ugrožen.
            </Paragraph>
            <Paragraph>
              Dijagnoza astme postavlja se na osnovu anamnestičkih podataka, fizikalnog pregleda i ispitivanja funkcije
              pluća.
            </Paragraph>
            <Paragraph>
              Hronična opstruktivna bolest pluća (HOBP) se karakteriše ograničenjem (smanjenjem) protoka vazduha u
              disajnim putevima koje je progresivno i udruženo sa zapaljenjskom reakcijom pluća na štetne čestice ili
              gasove. Za razliku od astme ograničeni protok vazdiuha kroz disajne puteve nije reverzibilan.
            </Paragraph>
            <Paragraph>
              Od HOBP najčešće obolevaju pušači ili bivši pušači, osobe koje žive u kućama sa mnogo gasova (sagorevanje
              u pećima za zagrevanje prostorija) ili one koje su radile na mestima gde je bilo puno prašine i dima.
              Većina bolesnika sa HOBP ima preko 40 godina, ali mogu oboleti i mlađi ljudi.
            </Paragraph>
            <Paragraph>
              Osobe koje boluju od HOBP žale se na kašalj i iskašljavanje sluzavog ispljuvka, otežano dišu, guše se,
              ostaju bez daha prilikom fizičkog napora i potrebno im je duže vreme da se oporave nakon prehlade. Sa
              napredovanjem bolesti, tegobe postaju sve izraženije. Većina pacijenata kasno se javlja lekaru i smatra da
              je kašalj normalna pojava zbog pušenja.
            </Paragraph>
            <Paragraph>
              Dijagnoza HOBP se postavlja na osnovu anamnestičkih podataka, fizikalnog pregleda, ispitivanja plućne
              funkcije i PA grafije pluča. U našoj ordinaciji možete uraditi:
            </Paragraph>
            <ul>
              <li>pulmolški pregled</li>
              <li>ispitivanje plućne funkcije (spirometriju)</li>
              <li>bronhodilatacijski test</li>
            </ul>
            <Divider sx={{ my: 2 }} />
            <b>
              Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!
              <br />
              Naš stručnjak iz oblasti pulmologije je dr sci med. Dejan Dimić, specijalista pneumoftiziologije.
            </b>
          </>
        );
      case 'kardiohirurgija':
        return (
          <>
            <Paragraph>
              Kardiohirurgija je specijalizovana grana hirurgije koja se bavi dijagnostikom i hirurškim lečenjem bolesti
              srca i krvnih sudova. U ovoj oblasti medicine, primarni cilj je da se kod pacijenata obezbedi normalan
              protok krvi i funkcija srca, čime se poboljšava kvalitet života i povećava životni vek. Kardiohirurške
              procedure obuhvataju zahvate kao što su:
            </Paragraph>
            <ul>
              <li>operacije koronarnih arterija (bajpas)</li>
              <li>zamena ili popravka srčanih zalistaka</li>
              <li>tretman aneurizmi</li>
              <li>lečenje urođenih srčanih mana</li>
            </ul>
            <Paragraph>
              Jedan od najčešćih zahvata u kardiohirurgiji je operacija bajpasa, koja se izvodi kod pacijenata sa
              suženim ili začepljenim arterijama kako bi se obezbedio bolji protok krvi do srca. Takođe, operacije
              srčanih zalistaka su od velike važnosti, jer omogućavaju vraćanje normalne funkcije zalistaka, čime se
              smanjuje pritisak na srce. Za pacijente sa ozbiljnim oštećenjima srca, transplantacija srca može biti
              jedina opcija, iako se primenjuje samo kada su sve druge metode iscrpljene.
            </Paragraph>
            <Paragraph>
              Razvoj minimalno invazivne kardiohirurgije doneo je mnoge prednosti, omogućavajući pacijentima brži
              oporavak i smanjujući rizik od komplikacija. Zahvaljujući napretku tehnologije, kardiohirurzi danas
              koriste sofisticirane alate čime se postiže visoka preciznost tokom intervencija. Nakon operacije,
              pacijenti prolaze kroz strogi režim postoperativne nege kako bi se osigurao njihov oporavak i smanjio
              rizik od komplikacija, poput infekcija ili aritmija.
            </Paragraph>
            <Paragraph>
              Kardiohirurgija je visoko interdisciplinarna oblast, što znači da kardiohirurzi blisko sarađuju sa
              kardiolozima, anesteziolozima i specijalistima iz drugih medicinskih oblasti kako bi osigurali najbolji
              mogući ishod za pacijenta. Kontinuirano istraživanje i razvoj u ovoj oblasti doprinose napretku u
              dijagnostici, tretmanima i tehnikama, što omogućava sve sigurnije i efikasnije zahvate.
            </Paragraph>
            <Divider sx={{ my: 2 }} />
            <b>
              Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!
              <br />
              Naš stručnjak u oblasti kardiohirurgije je dr sci med. Mladen J. Kočica.
            </b>
          </>
        );
      case 'ultrazvucna-dijagnostika':
        return (
          <>
            <Paragraph>
              Ultrazvučna dijagnostika predstavlja metodu pregleda tela na potpuno neinvazivan način i bez štetnih
              efekata na unutrašnje organe. Ovim pregledom se mogu dijagnostikovati brojna oboljenja, prate i kontrolišu
              brojna stanja i efekti terapije. Tehnika pregleda se zasniva na zvučnim talasima visoke frekvence koji
              prolaze kroz tkiva i organe, odbijaju se različitim brzinama i vizuelizuju na ekranu.
            </Paragraph>
            <Divider sx={{ my: 2 }} />
            <b>
              Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!
              <br />
              Naš stručnjak u oblasti ultrazvučne dijagnostike je dr Miloš Dujović.
            </b>
          </>
        );
      case 'terapija-bola':
        return (
          <>
            <Paragraph>
              Bol je individualno emocionalno neprijatno iskustvo i predstavlja najčešći razlog javljanja lekaru u
              svetu. Predstavlja signal nastalog ili pretećeg organskog ili funckionalnog oštećenja tkiva. Prema
              trajanju može biti akutni i hronični bol. Ukoliko akutni bol traje svakog dana duže od 3 meseca ili 50% i
              više dana duže od šest meseci onda prelazi u hroničan bol. Ukoliko se hroničan bol ne tretira adekvatno
              može postati bolest koja značajno smanjuje kvalitet života pacijenta i uzrokuje brojne funkcionalne,
              socijalne, socio-ekonomske i emotivne probleme. Najčešći uzroci hroničnog bola su degenerativne promene
              zglobova, prolapsi intervertebralnih diskusa, vaskularne bolesti i neuropatije. U našoj ordinaciji možete
              zakazati pregled i dobiti različite vrste tretmana bola.
            </Paragraph>
            <Divider sx={{ my: 2 }} />
            <b>
              Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!
              <br />
              Naš stručnjak za terapiju bola je kl. ass. dr Nemanja Dimić.
            </b>
          </>
        );
      case 'hepatologija':
        return (
          <>
            <Paragraph>
              Hepatitis je zajednički naziv za grupu bolesti označenih kao „upala jetre“ koje mogu biti zarazne i
              nezarazne etiologije.
            </Paragraph>
            <Paragraph>
              Hepatitis je zajednički naziv za grupu bolesti označenih kao „upala jetre“ koje mogu biti zarazne i
              nezarazne etiologije.
            </Paragraph>
            <Paragraph>
              Uzroci nezaraznih hepatitisa mogu biti metabolički poremećaji (npr.masna jetra), imunološki poremećaji
              (autoimuni hepatitis) ili štetni uticaji spoljašnje sredine (alkohol, toksini).
            </Paragraph>
            <Paragraph>
              Grupu zaraznih infektivnih hepatitisa izazivaju virusi sa izrazitim tropizmom za parenhim jetre. U
              zavisnosti od toga koja vrsta virusa izaziva oštećenje tkiva jetre, hepatitisi se dele na A B C D i E
              hepatitise. Hepatitis A i hepatitis E pripadaju grupi gastrointestinalnih infekcija a prenose se preko
              prljavih ruku, kontaminirane hrane ili vode. Hepatitis B C i D pripadaju grupi bolesti koje se uglavnom
              prenose putem zaražene krvi. Prema proceni svetske zdravstvene organizacije preko 90% osoba inficirani
              nekim od ovih virusa ne znaju da imaju infekciju jer su često asimptomatski sve do uznapredovalog
              stadijuma bolesti.
            </Paragraph>
            <Divider sx={{ my: 2 }} />
            <b>
              Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!
              <br />
              Naš stručnjak iz oblasti hepatologije je Prof. dr sci med Ksenija Bojović.
            </b>
          </>
        );
      case 'infektologija':
        return (
          <>
            <Paragraph>
              Terminom infektivne bolesti označavaju se sve bolesti koje nastaju kao posledica infekcije bakterijama,
              virusima, gljivicama i parazitima. Veliki broj infektivnih bolesti se prenosi sa čoveka na čoveka, ali
              postoje i one koje se šire putem vektora (ujed krpelja) ili nastaju nakon ujeda ili ogrebotina od strane
              životinje. Simptomi bolesti značajno variraju u zavisnosti od infektivnog uzroka i imunološkog statusa
              pacijenta.
            </Paragraph>
            <Paragraph>
              Najčešći simptomi bolesti su nespecifični: groznica, malaksalost, povišena temperatura, poremećaj apetita,
              ali se mogu javiti i vrlo specifične promene poput egzantema kože, simptoma od strane centralnog nervnog
              sistema, parestezije. Najveći broj infektivnih bolesti ne izaziva opasne komplikacije ali neki među njima
              poput upale pluća, meningitisa, HIV- infekcije mogu biti životno ugrožavajući. Takođe neke infektivne
              bolesti su povezane sa značajnom stopom razvoja karcnoma (HPV, EBV, Helicobacter pylori…)
            </Paragraph>
            <Divider sx={{ my: 2 }} />
            <b>
              Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!
              <br />
              Naš stručnjak iz oblasti infektologije je Prof. dr sci med Ksenija Bojović.
            </b>
          </>
        );
      case 'onkohirurgija':
        return (
          <>
            <Paragraph>
              Briga o zdravlju je od izuzetnog značaja, a najveću ulogu u prevenciji najtežih bolesti poput karcinoma
              imaju redovni kontrolni pregledi. Najčešći maligni tumor kod žena je rak dojke, a stopa incidence
              morbiditeta i mortaliteta od ovog karcinoma je u stalnom porastu. Neki od simptoma koji ukazuju na
              neophodnost onkološkog⁄onkohirurškog pregleda su:
            </Paragraph>
            <ul>
              <li>pojave čvorića u grudima, pazušnoj jami</li>
              <li>neobičan iscedak iz bradavice</li>
              <li>osetljiva, bolna mesta u grudima</li>
              <li>promena teksture i izgleda kože i bradavice</li>
            </ul>
            <Paragraph>
              Svake godine u svetu se dijagnostikuje preko 200 000 pacijenata obolelih od malignih melanoma kože, a u
              Srbiji više od 500 novoobolelih na godišnjem nivou. Maligni melanom kože se može javiti na bilo kom delu
              tela, ali se najčešće javlja na leđima kod muškaraca, odnosno na potkolenicama kod žena. Prilikom pregleda
              kože potrebno je obratiti pažnju na oblik, veličinu, boju i ivice pigmentovanih promena na koži.
            </Paragraph>
            <Paragraph>
              Karcinom štitaste žlezde se najčešće javlja između 30. i 50. godine života, tri puta češće kod žena nego
              kod muškaraca. Najčešći oblik karcinoma štitaste žlezde je papilarni karcinom (70%). Drugi po učestalosti
              je folikularni karcinom, dok su medularni i anaplaštični karcinomi retki, ali izuzetno agresivni sa malom
              stopom preživljavanja.
            </Paragraph>
            Kada je potrebno javiti se na pregled onkohirurga ?
            <ul>
              <li>prisustvo opipljivog čvorića žlezde ili opisanog na ultrazvučnom pregledu</li>
              <li>dugotrajna promuklost, kašalj</li>
              <li>izrasline na žlezdi koje se menjaju</li>
              <li>bol u grlu koji dugo traje</li>
              <li>bolovi u vratu koji se šire prema uhu</li>
              <li>stridorozno disanje (kod velikih tumora)</li>
            </ul>
            Među faktorima rizika za pojavu karcinoma štitaste žlezde ističe se njeno zračenje u detinjstvu i genska
            predispozicija.
            <Divider sx={{ my: 2 }} />
            <b>
              Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!
              <br />
              Naš stručnjak iz oblasti onkohirurgije je spec. dr Milan Gojgić.
            </b>
          </>
        );
      case 'neurologija':
        return (
          <>
            <Paragraph>
              Neurologija je medicinska disciplina koja istražuje strukturu, funkcije i bolesti nervnog sistema. Posebno
              se bavi opisom i objašnjenjem kliničke slike bolesti prouzrokovane patološkim procesima i lezijama u
              određenim strukturama nervnog sistema ili poremećajima u njegovom funkcionisanju.
            </Paragraph>
            <Paragraph>
              Neurologija i psihijatrija se delom prepliću. Predmet interesovanja neurologa su centralni nervni sistem
              (mozak i kičmena moždina), kao i njima bliske strukture krvnih sudova perifernih nerava i veza nerava sa
              muskulaturom.
            </Paragraph>
            <Divider sx={{ my: 2 }} />
            <b>Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!</b>
          </>
        );
      case 'hematologija':
        return (
          <>
            <Paragraph>
              Hematologija je grana interne medicine koja se bavi urođenim i stečenim poremećajima krvi, poremećajima
              zgrušavanja krvi i malignim bolestima. Najčešći simptomi zbog kojih se pacijenti javljaju kod hematologa
              su: slabost, malaksalost, noćni grčevi, lupanje srca, vrtoglavica, modrica na koži, učestalih infekcija,
              tromboze dubokih vena, otoci ekstremiteta, produženo krvarenje nakon povrede. Pregled hematologa se
              sastoji od detaljne anamneze o početku i trajanju postojećih tegoba, analize medicinske dokumetacije i
              kompletnog kliničkog pregleda sa palpacijom limfnih čvorova.
            </Paragraph>

            <Divider sx={{ my: 2 }} />
            <b>
              Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!
              <br />
              Naš stručnjak iz oblasti hematologije je dr Aleksandar Ljuboja.
            </b>
          </>
        );
      case 'ortopedija':
        return (
          <>
            <Paragraph>
              Ortopedija je medicinska specijalnost koja proučava, sprečava i leči bolesti, deformacije i povrede
              sistema za kretanje. Danas, najsvremenija oprema omogućava manju invazivnost zahvata, iako je značajan deo
              ortopedije hirurški zbog aktivnog, operativnog pristupa u terapiji mnogih stanja.
            </Paragraph>
            <Paragraph>
              Zadržala je i svoje istorijske karakteristike, podrazumevajući brigu o deformacijama skeleta sa opštim
              zadacima brige za invalide, pa tako sarađuje sa ostalim specijalnostima, kao što su pedijatrija,
              reumatologija i neonatologija.
            </Paragraph>
            <Divider sx={{ my: 2 }} />
            <b>Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!</b>
          </>
        );
      case 'endokrinologija':
        return (
          <>
            <Paragraph>
              Endokrinologija je grana interne medicine koji se bavi poremećajem rada žlezda sa unutrašnjim lučenjem i
              oboljenjima koji nastaju kao posledica tih poremećaja. U domen endokrinologije spada i
              najrasprostranjenije hronične nezarazne bolesti kao što su šećerna bolest, bolesti štitaste žlezde ali i
              mnoge druge poput poremećaja rada paraštitastih žlezda, hipofize, poremećaja muških i ženskih polnih
              hormona, poremećaja gustine kostiju (osteopenija, osteoporoza), metabolički sindrom, gojaznost,
              hiperholesterolemija i drugi.
            </Paragraph>
            <Divider sx={{ my: 2 }} />
            <b>Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!</b>
          </>
        );
      case 'infuziona-terapija':
        return (
          <>
            <Paragraph>
              Infuziona terapija, specijalno kreirana od strane naših lekara, bogata je tečnostima, antioksidantima,
              lekovima, vitaminima i nutrijentima i ordinira se kako bi pomogla u borbi protiv brojnih specifičnih
              simptoma koji svakodnevno utiču na kvalitet života. Cilj infuzione terapije je da značajno poboljša Vaše
              opšte stanje i omogući Vam da budete spremni za svakodnevne izazove.
            </Paragraph>
            Da li ste znali da…?
            <ul>
              <li>
                <b>Vitamini B kompleksa</b> doprinose smanjenju stresa, poboljšanju mentalnog zdravlja, snage i
                kognitivnih performansi.
              </li>
              <li>
                <b>Magnezijum</b> značajno pomaže u terapiji migrena, hroničnog bola, depresije i PMS-a.
              </li>
              <li>
                <b>Vitamin C</b>, pored izrazite antioksidativne funkcije, ima veoma važnu ulogu u očuvanju kvaliteta
                kože i usporavanju procesa starenja.
              </li>
              <li>
                <b>Glutation</b>, kao najjači antioksidant, ima ključnu ulogu u eliminaciji toksina iz ćelija, štiti od
                štetnih efekata zračenja, hemikalija i zagađivača iz spoljašnje sredine. Kako starite sposobnost Vašeg
                tela da proizvodi glutation se drastično smanjuje.
              </li>
            </ul>
            <Paragraph>
              Kale Medika nudi raznovrsne pakete infuzione terapije koji pomažu u ublažavanju brojnih simptoma i stanja
              kao što su dehidratacija, migrene, mamurluk, hronični bol, umor i brojni drugi.
            </Paragraph>
            <Divider sx={{ my: 2 }} />
            <b>Ne prepuštajte svoj život slučaju! Poverite svoje zdravlje stručnim licima!</b>
          </>
        );
      default:
        return <></>;
    }
  };
  return <Container sx={{ px: 1, fontSize: { sm: 16, md: 20 } }}>{getServiceDetailText()}</Container>;
};

export default ServiceDetailText;
