import { useEffect, useState } from 'react';
import image1a from '../assets/images/25Dimic.jpg';
import image1b from '../assets/images/27Dimic.jpg';
import image2 from '../assets/images/45Dimic.jpg';
import image3 from '../assets/images/40Dimic.jpg';
import image4 from '../assets/images/15Dimic.jpg';
import image5 from '../assets/images/42Dimic.jpg';
import CardioImage from '../assets/images/cardiology.jpg';
import PulmonologyImage from '../assets/images/pulmonology.jpg';
import UltrasoundImage from '../assets/images/ultrasound.jpg';
import PainTherapyImage from '../assets/images/pain-therapy.jpeg';
import HepatologyImage from '../assets/images/hepatology.jpg';
import InfectologyImage from '../assets/images/infectology.jpg';
import OncologyImage from '../assets/images/oncology.jpg';
import NeurologyImage from '../assets/images/neurology.jpg';
import HematologyImage from '../assets/images/hematology.jpg';
import OrtopediaImage from '../assets/images/ortopedia.jpg';
import EndocrinologyImage from '../assets/images/diabetology.jpg';
import InfusionImage from '../assets/images/infusion.jpg';
import CardioSurgeryImage from '../assets/images/invasive-cardiology.jpg';

const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject(src);
    };
    img.onabort = () => {
      reject(src);
    };
    img.src = src;
  });
};

const useImagePreloader = (): { landingImages: string[]; serviceImages: string[]; imagesPreloaded: boolean } => {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);
  const landingImages = [image1a, image1b, image2, image3, image4, image5];
  const serviceImages = [
    CardioImage,
    PulmonologyImage,
    UltrasoundImage,
    PainTherapyImage,
    HepatologyImage,
    InfectologyImage,
    OncologyImage,
    NeurologyImage,
    HematologyImage,
    OrtopediaImage,
    EndocrinologyImage,
    InfusionImage,
    CardioSurgeryImage,
  ];
  useEffect(() => {
    let isCancelled = false;

    const effect = async () => {
      if (isCancelled) {
        return;
      }
      const imageList = [...landingImages, ...serviceImages];
      const imagesPromiseList = imageList.map((i) => preloadImage(i));

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    };

    effect();

    return () => {
      isCancelled = true;
    };
  });

  return { landingImages, serviceImages, imagesPreloaded };
};

export default useImagePreloader;
